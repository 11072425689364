import { useSidebarContext } from "../../context/EventSidebarContext"
import { useEventNavigation } from "../../context/EventNavigationContext"
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { ProductAttachmentOptions } from "../../components/ProductAttachment/ProductAttachmentOptions"

export const useConfigureAppSidebar = (boothSlug: string) => {
  const sidebarContext = useSidebarContext()
  const navigationContext = useEventNavigation()
  useEffect(() => {
    sidebarContext.setCollapsed(true)
    sidebarContext.setExitAction({
      label: "Overview",
      onClick: () => navigationContext.navigateToBooth(boothSlug),
    })
    return () => {
      sidebarContext.setCollapsed(false)
      sidebarContext.setExitAction()
    }
  }, [boothSlug])
}

export const useCurrentAttachmentController: () => [
  number,
  ProductAttachmentOptions,
  MutableRefObject<HTMLDivElement>,
  (i: number, scrollThere?: boolean, options?: ProductAttachmentOptions) => void
] = () => {
  const [[currentAttachment, currentAttachmentOptions], setCurrentAttachment] =
    useState<[number, ProductAttachmentOptions]>([0, { type: "none" }])

  const attachmentsContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

  const set = useCallback((i: number, scrollTo: boolean = false, options) => {
    setCurrentAttachment([i, options || { type: "none" }])
    const parent = attachmentsContainerRef.current
    if (!parent || !scrollTo) {
      return
    }
    window.scrollTo({
      top: (parent.childNodes[i] as HTMLDivElement).offsetTop,
      behavior: "smooth",
    })
  }, [])

  return [
    currentAttachment,
    currentAttachmentOptions,
    attachmentsContainerRef,
    set,
  ]
}
