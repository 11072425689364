import React from "react"
import { Product, ProductDetails } from "../../types/Product"
import * as S from "./styled"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "../../theme/light"
//@ts-ignore
import { ReactComponent as Arrow } from "../../icons/ic-arrow-top-right.svg"
import { useEventNavigation } from "../../context/EventNavigationContext"
import { Checkbox } from "../Checkbox"
import { useInterests } from "../../context/InterestsContext"
import { Booth } from "../../types/Booth"
import {
  FactSheetAdvantages,
  FactSheetBullets,
  FactSheetStatus,
  FactSheetText,
} from "../FactSheetComponents"

const FactSheet: React.FC<{ data: ProductDetails }> = ({ data }) => (
  <S.FactSheetContainer>
    {data.description && (
      <S.FactSheetItem title={"Description"} initiallyExpanded>
        <FactSheetText content={data.description || ""} />
      </S.FactSheetItem>
    )}
    {data.mainAdvantages && data.mainAdvantages.length > 0 && (
      <S.FactSheetItem title={"Main advantages"}>
        <FactSheetAdvantages content={data.mainAdvantages} />
      </S.FactSheetItem>
    )}
    {data.keyFacts && (
      <S.FactSheetItem title={"Key facts"}>
        <FactSheetBullets content={data.keyFacts || ""} />
      </S.FactSheetItem>
    )}
    {data.trlLevel?.number && (
      <S.FactSheetItem title={"Status"}>
        <FactSheetStatus
          status={data.trlLevel.number}
          allLevels={data.trlLevel.allProductTrlLevels}
        />
      </S.FactSheetItem>
    )}
    {/* {data.criticalAnalysis && (
      <S.FactSheetItem title={"Critical analysis"}>
        <FactSheetBullets content={data.criticalAnalysis} />
      </S.FactSheetItem>
    )} */}
    {data.intellectualProperty && (
      <S.FactSheetItem title={"Intellectual property"}>
        <FactSheetBullets content={data.intellectualProperty} />
      </S.FactSheetItem>
    )}
    {data.applicationsCompliancy && (
      <S.FactSheetItem title={"Applications / Compliance"}>
        <FactSheetBullets content={data.applicationsCompliancy} />
      </S.FactSheetItem>
    )}
    <S.FactSheetContactDetails
      name={data.contactInfo?.name}
      role={data.contactInfo?.jobTitle}
      email={data.contactInfo?.email}
      division={data.division?.name}
      company={data.company?.name}
    />
  </S.FactSheetContainer>
)

export const ProductDetailsSidebar: React.FC<{
  product: Product
  next?: Product | Booth
  boothName: string
}> = ({ product, boothName, next, ...props }) => {
  const { getProductUrl, getBoothUrl } = useEventNavigation()
  const { products: interests, toggleProduct, disabled } = useInterests()
  const allowPDFDownload = !product.details.disableFactSheetDownload

  console.log("prod", product.factSheetDownload)

  return (
    <ThemeProvider theme={lightTheme}>
      <S.Container {...props}>
        <S.InnerContainer id={"sidebarInnerContainer"}>
          <S.HeaderContainer>
            <S.Overline>{boothName}</S.Overline>
            <S.TitleContainer>
              <S.Title>{product.details.name}</S.Title>
              {product.details.lastModified && (
                <S.LastModified>
                  <div>Last modified</div>
                  {product.details.lastModified}
                </S.LastModified>
              )}
            </S.TitleContainer>
            <S.Platform>
              {product.details.platform?.name && (
                <>Platform: {product.details.platform?.name}</>
              )}
            </S.Platform>

            {!disabled && (
              <S.RequestMoreInfoContainer
                onClick={() => toggleProduct(product, "This product")}
              >
                <Checkbox
                  id={"request"}
                  checked={interests[product.parentBoothSlug]?.includes(
                    product.slug
                  )}
                />
                <label htmlFor={"request"}>
                  <span>I'm interested in this topic</span>
                </label>
              </S.RequestMoreInfoContainer>
            )}
            {product.factSheetDownload === "default" ? (
              allowPDFDownload && (
                <S.DownloadPdfContainer>
                  <a
                    href={`https://europe-central2-motherson-282914.cloudfunctions.net/Cloud-storage-pdf?product_id=${product.details.id}&type=regular`}

                    target="_blank"
                  >
                    <S.Icon />
                    <span>Download PDF</span>
                  </a>
                </S.DownloadPdfContainer>
              )
            ) : product.factSheetDownload === "enabled" ? (
              <S.DownloadPdfContainer>
                <a
                  href={`https://europe-central2-motherson-282914.cloudfunctions.net/Cloud-storage-pdf?product_id=${product.details.id}&type=regular`}
                  target="_blank"
                >
                  <S.Icon />
                  <span>Download PDF</span>
                </a>
              </S.DownloadPdfContainer>
            ) : (
              ""
            )}
          </S.HeaderContainer>
          <FactSheet data={product.details} />
        </S.InnerContainer>
        {next && (
          <S.NextContainer id={"nextContainer"}>
            <S.NextOverline>Next</S.NextOverline>
            <S.NextProductTitle
              to={
                "details" in next ? getProductUrl(next) : getBoothUrl(next.slug)
              }
              onClick={() => window.scrollTo({ top: 0 })}
            >
              {"details" in next ? next.details.name : next.name} <Arrow />
            </S.NextProductTitle>
          </S.NextContainer>
        )}
      </S.Container>
    </ThemeProvider>
  )
}
