import React, { useState } from "react"
import { Product } from "../../types/Product"
import { Booth } from "../../types/Booth"
import { ProductAttachment } from "../../components/ProductAttachment"

import * as S from "./styled"
import { AttachmentsPager } from "../../components/AttachmentsPager"
import { useConfigureAppSidebar, useCurrentAttachmentController } from "./hooks"
import { ProductAttachmentOptions } from "../../components/ProductAttachment/ProductAttachmentOptions"
import { FullScreenImageViewer } from "../../components/FullScreenImageViewer"
//@ts-ignore
import { ReactComponent as FullScreenIcon } from "../../icons/ic-full-screen.svg"

export const ProductDetailsLayout: React.FC<{
  product: Product
  boothName: string
  boothSlug: string
  next?: Product | Booth
  previous?: Product | Booth
}> = ({ product, boothName, boothSlug, next, previous }) => {
  useConfigureAppSidebar(boothSlug)
  const [
    currentAttachment,
    currentAttachmentOptions,
    attachmentsContainerRef,
    setCurrent,
  ] = useCurrentAttachmentController()

  const [fullScreen, setFullScreen] = useState<boolean>(false)
  return (
    <S.Container>
      <S.Global />

      <S.AttachmentsContainerOuter>
        <AttachmentsPager
          visible={!fullScreen}
          count={product.details.attachments.length}
          current={currentAttachment}
          onDotClick={i => setCurrent(i, true)}
        />
        <S.AttachmentsContainer ref={attachmentsContainerRef}>
          {product.details.attachments.map((at, i) => (
            <ProductAttachment
              setFullScreen={setFullScreen}
              key={at.is3dModel ? at.model : at.url}
              attachment={at}
              onVisible={(options?: ProductAttachmentOptions) =>
                setCurrent(i, false, options)
              }
              isFullScreen={fullScreen}
              as={
                !at.is3dModel
                  ? S.RegularAttachmentComponent
                  : S.SceneAttachmentComponent
              }
            />
          ))}
        </S.AttachmentsContainer>
      </S.AttachmentsContainerOuter>

      <S.Footer
        previous={previous}
        currentAttachment={product.details.attachments[currentAttachment]}
      />

      <S.SidebarContainer notClickable={fullScreen}>
        <S.SidebarIntermediateContainer>
          <S.SidebarInnerContainer>
            <S.Sidebar
              boothName={boothName}
              next={next}
              product={product}
              visible={
                !(
                  fullScreen && currentAttachmentOptions?.type === "hideSidebar"
                )
              }
            />
          </S.SidebarInnerContainer>
        </S.SidebarIntermediateContainer>
      </S.SidebarContainer>

      <S.FullScreenButton
        label={"View fullscreen"}
        onClick={() => setFullScreen(true)}
        icon={<FullScreenIcon />}
        visible={!fullScreen && currentAttachmentOptions?.type !== "none"}
      />

      <FullScreenImageViewer
        //@ts-ignore
        image={
          currentAttachmentOptions?.type === "fullScreenImageViewer" &&
          currentAttachmentOptions.image
        }
        close={() => setFullScreen(false)}
        visible={
          currentAttachmentOptions?.type === "fullScreenImageViewer" &&
          fullScreen
        }
      />
    </S.Container>
  )
}
