import React, { useEffect } from "react"
import { DefaultBg } from "../../../components/Backgrounds"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { useEventAssetsPreloader } from "../../../hooks/useEventAssetsPreloader"
import { isInIframe } from "../../../util/isInIframe"

import * as S from "./styled"
import { DefaultAssetLoader } from "./loaders/DefaultAssetLoader"

export const Preloader: React.FC<RouteComponentProps<{ slug: string }>> =
  () => {
    const { assets, progress, onFailure, onSuccess, eventSlug } =
      useEventAssetsPreloader()
    const history = useHistory()

    useEffect(() => {
      if (progress === 1) {
        const urlParams = new URLSearchParams(history.location.search)
        const path = urlParams.get("path")
        if (path) {
          history.push(`${path}`)
          return
        }
        history.push(`/${eventSlug}/scene`)
      }
      // intentionally omitting history.location here
    }, [progress])

    return (
      <>
        <DefaultBg />
        {!isInIframe() && <S.Logo />}
        <S.Container>
          <S.Title>Loading the exhibition</S.Title>
          <S.Loader percent={progress} />
          <S.Percentage>{`${Math.round(progress * 100)}%`}</S.Percentage>
          <S.OffScreen>
            {assets.map((asset, i) => (
              <DefaultAssetLoader
                key={asset.itemId + i}
                asset={asset}
                onSuccess={onSuccess}
                onFail={onFailure}
              />
            ))}
          </S.OffScreen>
        </S.Container>
      </>
    )
  }
