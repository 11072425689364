import React, { useEffect } from "react"
import { ProductDetails } from "../../types/Product"
import { ProductAttachmentOptions } from "./ProductAttachmentOptions"
import styled from "styled-components"
import device, { size } from "../../theme/devices"
import { useMedia } from "use-media"
import { DatoCmsAsset } from "../DatoCmsAsset"

const Container = styled.div`
  > :first-child {
    width: 100%;
    height: 100%;
  }
  @media (${device.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > :first-child {
      width: 100%;
      height: 200%;
    }
  }
`

const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DownloadButton = styled.a`
  background: ${props => props.theme.backgroundColor};
  padding: 16px 12px;
  color: ${props => props.theme.textColor};
  margin: 0 20px;
  text-decoration: none;
  font-size: 14px;
`

export const PdfAttachment = React.forwardRef<
  HTMLDivElement,
  {
    attachment: ProductDetails["attachments"][number]
    setOptions: (options: ProductAttachmentOptions) => void
  }
>(({ attachment, setOptions, ...props }, ref) => {
  if (attachment.is3dModel)
    throw new Error(`Can't render 3d model as PdfAttachment`)
  if (!attachment.url) throw new Error(`Invalid image`)

  const isPdf = attachment.mimeType.includes("pdf")
  if (!isPdf) {
    throw new Error("Invalid pdf")
  }

  const canRenderPdf = useMedia({ minWidth: size.desktop })

  useEffect(() => {
    setOptions({ type: "none" })
  }, [setOptions])

  return (
    <Container ref={ref} {...props}>
      {canRenderPdf ? (
        <DatoCmsAsset asset={attachment} />
      ) : (
        <DownloadContainer>
          <DownloadButton href={attachment.url} target={"_blank"}>
            Download {attachment.title || attachment.filename}
          </DownloadButton>
        </DownloadContainer>
      )}
    </Container>
  )
})
