import React from "react"

import * as S from "./styled"
// @ts-ignore
import warningIcon from "../../icons/ic-warning.svg"
import { Product } from "../../types/Product"
import { Booth } from "../../types/Booth"
import { useEventNavigation } from "../../context/EventNavigationContext"

export const ProductDetailsFooter: React.FC<{
  previous?: Product | Booth
  customPrevious?: { overline: string; title: string; onClick: () => void }
  currentAttachment?: Product["details"]["attachments"][number]
}> = ({ previous, customPrevious, currentAttachment, ...props }) => {
  const { getProductUrl, getBoothUrl } = useEventNavigation()
  return (
    <S.Container {...props}>
      {!customPrevious && previous && "slug" in previous && (
        <S.Previous>
          <S.PreviousOverline>Previous</S.PreviousOverline>
          <S.PreviousTitle
            to={
              "details" in previous
                ? getProductUrl(previous)
                : getBoothUrl(previous.slug)
            }
            onClick={() => window.scrollTo({ top: 0 })}
          >
            <S.ArrowLeft />
            {"details" in previous ? previous.details.name : previous.name}
          </S.PreviousTitle>
        </S.Previous>
      )}
      {customPrevious && (
        <S.Previous>
          <S.PreviousOverline>{customPrevious.overline}</S.PreviousOverline>
          {/*@ts-ignore*/}
          <S.PreviousTitle as={"span"} onClick={customPrevious.onClick}>
            <S.ArrowLeft />
            {customPrevious.title}
          </S.PreviousTitle>
        </S.Previous>
      )}
      <S.OtherItems>{props.children}</S.OtherItems>
      <S.ConfidentialLabel
        visible={currentAttachment?.visibility === "confidential"}
      >
        <img src={warningIcon} alt={"Warning"} />
        <span>Strictly confidential</span>
      </S.ConfidentialLabel>
    </S.Container>
  )
}
